import * as React from 'react'
import Helmet from 'react-helmet'

const PageTitle = ({ children }) => {

    return (
        <>
            <Helmet>
                <title>{children}</title>
            </Helmet>
            <h1 className="base-title text-primary-dark-green mt-2">
                {children}
            </h1>
            <hr className="border-t border-gray-500 border-opacity-50" />
        </>
    )
}

export default PageTitle